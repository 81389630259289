import React from "react"

const InfoIcon = ({ icon, title, style, children }) => {
  var infoStyle = Object.assign({
    display: 'flex',
    width: '280px',
    flexFlow: 'row wrap',
    margin: '20px 10px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: '#4A4A4A',
  }, style);

  const iconStyle = {
    width: '5%',
    margin: '0 10px',
    fontSize: '1.2em',
  };

  const textStyle = {
    display: 'flex',
    flexFlow: 'column',
    margin: '0 10px',
    width: '75%',
  };

  const titleStyle = {
    fontWeight: 'bold',
  };

  return (
    <div style={infoStyle}>
      <i className={icon} style={iconStyle} aria-hidden="true" />
      <div style={textStyle}>
        <div style={titleStyle}>{title}</div>
        <div>{children}</div>
      </div>
    </div>
  )
}

export default InfoIcon
